export default {
    tableFields:   [
        {
            name:      '__component:badge-column',
            title:     '',
            dataClass: 'text-center',
            width:     '4%'
        },
        {
            name:      'zone_event.name',

            sortField: 'zone_event.name',
            title:'Zona de Evento',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            
        },     
        {
            name:      'membership.name',
            sortField: 'membership.name',
            title:'Membresia',
            titleClass: "center aligned",
            dataClass:  "center aligned",
            
        },
      
      
        {
            name:       '__slot:active',
            title:      'Active',
            title:'Estatus',
            titleClass: "center aligned",
            dataClass:  "center aligned",
           
        },
        {
            name:       '__slot:actions',
            title:      'Acciones',            
            titleClass: "center aligned",
            dataClass:  "center aligned",
           
        },
    ],
    sortFunctions: {
        'name': function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        },
    }
}
